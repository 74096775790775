export default [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: true
    },
    component: () => import('@/views/home'),
    // 重定向到home下面
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/nav/homepage'),
        meta: {
          auth: true,
          keepAlive: true // 需要被缓存
        }
      },
      // {
      //   path: 'trade',
      //   component: () => import('@/views/nav/trade'),
      //   meta: {
      //     keepAlive: true // 需要被缓存
      //   }
      // },
      // {
      //   path: 'chart',
      //   component: () => import('@/views/nav/chart'),
      //   meta: {
      //     keepAlive: true // 需要被缓存
      //   }
      // },
      // {
      //   path: 'warehouse',
      //   component: () => import('@/views/nav/warehouse'),
      //   meta: {
      //     keepAlive: true // 需要被缓存
      //   }
      // },
      // {
      //   path: 'user',
      //   component: () => import('@/views/nav/user'),
      //   meta: {
      //     keepAlive: true // 需要被缓存
      //   }
      // },
    ],
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      auth: true
    },
    component: () => import('@/views/user'),
  },
  {
    path: '/user/deposit',
    name: 'deposit',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/deposit'),
  },
  {
    path: '/user/withdraw',
    name: 'withdraw',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/withdraw'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false
    },
    component: () => import('@/views/login'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      auth: false
    },
    component: () => import('@/views/register'),
  },
  {
    path: '/user/walletLog',
    name: 'walletLog',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/walletLog'),
  },
  {
    path: '/user/profile',
    name: 'profile',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/profile'),
  },
  {
    path: '/user/bindCard',
    name: 'bindCard',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/bindCard'),
  },
  {
    path: '/user/news',
    name: 'news',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/news'),
  },
  {
    path: '/user/loginPassword',
    name: 'loginPassword',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/loginPassword'),
  },
  {
    path: '/user/payPassword',
    name: 'payPassword',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/payPassword'),
  },
  {
    path: '/user/vip',
    name: 'vip',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/vip'),
  },
  {
    path: '/content',
    name: 'content',
    meta: {
      auth: true
    },
    component: () => import('@/views/common/content'),
  },
  {
    path: '/term',
    name: 'term',
    meta: {
      auth: false
    },
    component: () => import('@/views/term'),
  },
  {
    path: '/user/invite',
    name: 'invite',
    meta: {
      auth: true
    },
    component: () => import('@/views/user/invite'),
  },
]
